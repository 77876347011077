import React, { useEffect, useState } from 'react';
import {
  disableUser,
  enableUser,
  useGetUsers,
} from '../../services/userServices';
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
} from '@mui/material';
import {
  ArrowDropDown,
  ArrowDropUp,
  NoAccountsOutlined,
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import { mutate } from 'swr';

const DashboardPage = () => {
  const [showActive, setShowActive] = useState(true);
  const [showInactive, setShowInactive] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [sortingMode, setSortingMode] = useState({
    item: 'lastRefreshTime',
    order: 'desc',
  });

  const token = localStorage.getItem('token');

  const { usersList, isLoading, isError } = useGetUsers(token);

  const handleDisableUser = (user) => {
    // console.log('User ID:', user);
    if (user.disabled) {
      //   console.log('User is disabled');
      enableUser(user.uid).then((data) => {
        // console.log('Data:', data);
        toast.success('Usuário ativado com sucesso');
        mutate('usersList');
      });
    }
    if (!user.disabled) {
      //   console.log('User is enabled', user);
      disableUser(user.uid).then((data) => {
        // console.log('Data:', data);
        toast.success('Usuário desativado com sucesso');
        mutate('usersList');
      });
    }
  };

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color='primary' />
        Carregando...
      </Backdrop>
    );
  }

  if (isError) {
    return <div>Erro ao carregar</div>;
  }

  if (!usersList) {
    return <div>Nenhum usuário encontrado</div>;
  }

  const handleSorting = (item) => {
    if (sortingMode.item === item) {
      setSortingMode((old) => ({
        item: old.item,
        order: old.order === 'asc' ? 'desc' : 'asc',
      }));
    } else {
      setSortingMode({
        item: item,
        order: 'asc',
      });
    }
  };

  return (
    <div className='flex flex-col gap-4 bg-slate-50 items-center'>
      <h1 className='font-semibold text-3xl mt-10 m-auto'>Lista de Usuários</h1>
      <div className='w-11/12'>
        <div className='flex flex-col gap-2 w-fit'>
          <label className='font-semibold' htmlFor='filterInput'>
            Filtrar por e-mail
          </label>
          <input
            type='text'
            id='filterInput'
            placeholder='Digite um e-mail'
            className='p-2 border-2 border-sky-200 rounded-md'
            onChange={handleFilter}
          />
        </div>
      </div>
      <div className='w-11/12'>
        <div className='flex flex-col p-2 w-fit'>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => setShowActive(e.target.checked)}
                  checked={showActive}
                />
              }
              label={`Ativos (${
                usersList?.filter((user) => user.disabled === false).length
              })`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => setShowInactive(e.target.checked)}
                  checked={showInactive}
                />
              }
              label={`Inativos (${
                usersList?.filter((user) => user.disabled === true).length
              })`}
            />
          </FormGroup>
        </div>
      </div>
      <div className='w-11/12 bg-slate-200 border-2 border-sky-500 rounded-sm p-4 m-auto'>
        <table className='w-full'>
          <thead className=''>
            <tr>
              <th>
                <div className='flex items-center justify-center'>
                  <p>E-mail</p>
                  {sortingMode.order === 'asc' ? (
                    <ArrowDropDown onClick={() => handleSorting('email')} />
                  ) : (
                    <ArrowDropUp onClick={() => handleSorting('email')} />
                  )}
                </div>
              </th>
              <th>
                <div className='flex items-center justify-center'>
                  <p>Último Acesso</p>
                  {sortingMode.order === 'asc' ? (
                    <ArrowDropDown
                      onClick={() => handleSorting('lastRefreshTime')}
                    />
                  ) : (
                    <ArrowDropUp
                      onClick={() => handleSorting('lastRefreshTime')}
                    />
                  )}
                </div>
              </th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody className='mt-4'>
            {usersList
              ?.filter((item) => {
                if (filterText === '') {
                  return item;
                } else if (
                  item.email.toLowerCase().includes(filterText.toLowerCase())
                ) {
                  return item;
                }
              })
              .filter((user) => {
                if (showActive && showInactive) {
                  return user;
                } else if (showActive && user.disabled === false) {
                  return user;
                } else if (showInactive && user.disabled === true) {
                  return user;
                }
              })
              .sort((a, b) => {
                if (sortingMode.order === 'asc') {
                  // check if the item lastRefreshTime and sort accordingly
                  if (sortingMode.item === 'lastRefreshTime') {
                    return new Date(a.metadata[sortingMode.item]) >
                      new Date(b.metadata[sortingMode.item])
                      ? 1
                      : -1;
                  } else {
                    return a[sortingMode.item] > b[sortingMode.item] ? 1 : -1;
                  }
                } else {
                  // check if the item lastRefreshTime and sort accordingly
                  if (sortingMode.item === 'lastRefreshTime') {
                    return new Date(a.metadata[sortingMode.item]) <
                      new Date(b.metadata[sortingMode.item])
                      ? 1
                      : -1;
                  } else {
                    return a[sortingMode.item] < b[sortingMode.item] ? 1 : -1;
                  }
                }
              })
              .map((user) => (
                <tr
                  key={user.id}
                  className='border-2 border-gray-500 hover:bg-sky-200 h-20'
                >
                  <td className='text-center'>{user.email}</td>
                  <td className='text-center'>
                    {new Date(
                      user.metadata.lastRefreshTime
                    ).toLocaleDateString()}
                  </td>
                  <td className='text-center'>
                    {user.disabled ? 'Desativado' : 'Ativo'}
                  </td>
                  <td className='text-center'>
                    <button
                      className={`${
                        user.disabled ? 'bg-emerald-700' : 'bg-red-400'
                      } p-2 rounded-md w-24 text-white font-semibold hover:bg-opacity-80 transition-colors duration-300 ease-in-out`}
                      onClick={() => handleDisableUser(user)}
                    >
                      {user.disabled ? 'Ativar' : 'Desativar'}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DashboardPage;
